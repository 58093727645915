/**
  Note this is currently the EDF set of styles
  We need to look at other familys and which properties would need changing
  to create some base whitelabel styles, and some brand specific overrides:
  - font size & line height likely reusable
  - weights may need to change based on the font family chosen
  - letter spacing most likely to change based on font family
 */
@mixin set-vars() {
  --typog-default-heading-family: 'smileyface', sans-serif;
  --typog-default-body-family: 'Inter', sans-serif;

  --typog-d1-weight: 700; //700 - bold
  --typog-d1-size: 50px;
  --typog-d1-line-height: 65px;
  --typog-d1-letter-spacing: 0.6px;
  --typog-sm-d1-size: 42px;
  --typog-sm-d1-line-height: 54px;
  --typog-sm-d1-letter-spacing: 0;

  --typog-d2-weight: 700; //700 = bold
  --typog-d2-size: 32px;
  --typog-d2-line-height: 42px;
  --typog-d2-letter-spacing: 0.6px;
  --typog-sm-d2-size: 28px;
  --typog-sm-d2-line-height: 37px;
  --typog-sm-d2-letter-spacing: 0.4px;

  --typog-h1-weight: 700; //700 = bold
  --typog-h1-size: 42px;
  --typog-h1-line-height: 54px;
  --typog-h1-letter-spacing: 0.6px;
  --typog-sm-h1-size: 34px;
  --typog-sm-h1-line-height: 44px;
  --typog-sm-h1-letter-spacing: 0.4px;

  --typog-h2-weight: 700; //700 = bold
  --typog-h2-size: 32px;
  --typog-h2-line-height: 42px;
  --typog-h2-letter-spacing: 0.6px;
  --typog-sm-h2-size: 28px;
  --typog-sm-h2-line-height: 37px;
  --typog-sm-h2-letter-spacing: 0.4px;

  --typog-h3-weight: 700; //700 = bold
  --typog-h3-size: 26px;
  --typog-h3-line-height: 33px;
  --typog-h3-letter-spacing: 0.4px;
  --typog-sm-h3-size: 24px;
  --typog-sm-h3-line-height: 32px;
  --typog-sm-h3-letter-spacing: 0.4px;

  --typog-h4-weight: 700; //700 = bold
  --typog-h4-size: 22px;
  --typog-h4-line-height: 29px;
  --typog-h4-letter-spacing: 0.4px;
  --typog-sm-h4-size: 20px;
  --typog-sm-h4-line-height: 26px;
  --typog-sm-h4-letter-spacing: 0.4px;

  --typog-h5-weight: 700; //700 = bold
  --typog-h5-size: 20px;
  --typog-h5-line-height: 26px;
  --typog-h5-letter-spacing: 0.4px;
  --typog-sm-h5-size: 17px;
  --typog-sm-h5-line-height: 23px;
  --typog-sm-h5-letter-spacing: 0.4px;

  //todo: not defined in xd
  --typog-h6-weight: 700; //700 = bold
  --typog-h6-size: 16px;
  --typog-h6-line-height: 150%;
  --typog-h6-letter-spacing: 0.02em;
  --typog-h6-text-transform: uppercase;
  --typog-sm-h6-size: 14px;

  --typog-button-font: var(--typog-default-heading-family);
  --typog-button-weight: bold;
  --typog-button-size: 19px;
  --typog-button-line-height: 24px;
  --typog-button-letter-spacing: 0.4px;
  --typog-sm-button-size: 19px;

  //todo: button selected no vars

  --typog-body-large-weight: normal;
  --typog-body-large-size: 18px;
  --typog-body-large-line-height: 28px;
  --typog-sm-body-large-size: 16px;

  --typog-body-large-bold-weight: 700;
  --typog-body-large-bold-size: 18px;
  --typog-body-large-bold-line-height: 28px;
  --typog-sm-body-large-bold-size: 16px;

  --typog-body-regular-weight: normal;
  --typog-body-regular-size: 16px;
  --typog-body-regular-line-height: 24px;
  --typog-sm-body-regular-size: 16px;
  --typog-sm-body-regular-line-height: 24px;

  --typog-body-bold-weight: bold;
  --typog-body-bold-size: 16px;
  --typog-body-bold-line-height: 24px;
  --typog-sm-body-bold-size: 16px;
  --typog-sm-body-bold-line-height: 24px;

  --typog-body-link-weight: bold;
  --typog-body-link-size: 16px;
  --typog-body-link-line-height: 24px;
  --typog-body-link-text-decoration-line: underline;
  --typog-sm-body-link-size: 16px;
  --typog-sm-body-link-line-height: 24px;

  --typog-caption-bold-weight: bold;
  --typog-caption-bold-size: 14px;
  --typog-caption-bold-line-height: 18px;
  --typog-sm-caption-bold-size: 12px;
  --typog-sm-caption-bold-line-height: 16px;

  --typog-caption-regular-weight: normal;
  --typog-caption-regular-size: 14px;
  --typog-caption-regular-line-height: 21px;
  --typog-sm-caption-regular-size: 14px;
  --typog-sm-caption-regular-line-height: 21px;

  --typog-caption-link-weight: bold;
  --typog-caption-link-size: 14px;
  --typog-caption-link-line-height: 21px;
  --typog-caption-link-text-decoration-line: underline;
  --typog-sm-caption-link-size: 14px;
  --typog-sm-caption-link-line-height: 21px;

  //todo: these are made up (copied from sales 2.0 current code) - need definitions from Chimmy
  --typog-menu-link-weight: normal;
  --typog-menu-link-size: 16px;
  --typog-menu-link-line-height: 24px;
  --typog-menu-link-text-decoration-line: underline;
  --typog-menu-link-letter-spacing: 0.05em;
  //--typog-sm-menu-link-size: 14px;
  //--typog-sm-menu-link-line-height: 21px;

  --typog-header-link-weight: normal;
  --typog-header-link-size: 20px;
  --typog-header-link-line-height: 24px;
  --typog-header-link-text-decoration-line: none;
  --typog-header-link-letter-spacing: calc(0.1em / 3);
  --typog-header-link-family: var(--typog-default-body-family);
  --typog-sm-header-link-size: 18px;
  --typog-sm-header-link-line-height: 24px;
}
