@use "../overrides/whitelabel" as wlo;
@use "../abstracts/typography-presets/ao" as typography-preset;
@use '../abstracts/variables/all' as v;

/**
  The exact palette and mapping is still being finalised, the following is a guide for light themes only.
  (Note for dark themes, Midtone would remain the same and the rest of the palette would flip

  On EDF/Whitelabel designs the colors map as follows:

  Darker:   800 *
  Dark:     700 *
  Midtone:  600
  Light:    500 *
  Lighter:  400
  Lightest: 300

  Tones map slightly differently:
  Black:    900
  Darkest:  800
  Darker:   700
  Dark:     600
  Midtone:  500
  Light:    400
  Lighter:  300
  Lightest: 200
  White:    100
 */
@mixin ao() {
  @include v.defaults();

  --primary-800: #02422B;
  --primary-700: #03724A;
  --primary-600: #12C35A;
  --primary-500: #4EEF8F;
  --primary-400: #BEFCC8;
  --primary-300: #F0FEF3;

  --secondary-800: #043C75;
  --secondary-700: #0565C2;
  --secondary-600: #0565C2;
  --secondary-500: #4A6DCE;
  --secondary-400: #A58CC9;
  --secondary-300: #DCD2EA;

  --success-800: #204814;
  --success-700: #32701E;
  --success-600: #3B8524;
  --success-500: #55C134;
  --success-400: #84D76A;
  --success-300: #B6E7A7;

  --warning-800: #B87900;
  --warning-700: #EB9A00;
  --warning-600: #FFC453;
  --warning-500: #FFD585;
  --warning-400: #FFDE9E;
  --warning-300: #FFE7B8;

  --error-800: #872221;
  --error-700: #9C2726;
  --error-600: #AF2C2B;
  --error-500: #D55453;
  --error-400: #E9A5A5;
  --error-300: #F3CECE;

  --tone-900: #0D0D0D;
  --tone-800: #262626;
  --tone-700: #414141;
  --tone-600: #676767;
  --tone-500: #757575;
  --tone-400: #D9D9D9;
  --tone-300: #EBEBEB;
  --tone-200: #F6F6F6;
  --tone-100: #ffffff;

  //todo: these should be deprecated but setting here to default to colors from above palette at least
  --accent: #011F44;
  --warn: var(--warning-600);
  --accept: var(--success-600);
  --primary-contrast: var(--tone-100);

  --error: var(--error-600);
  --warning: var(--warning-600);
  --success: var(--success-600);

  //main copy, field values
  --text-color: var(--tone-800);
  //field labels, hints
  --text-color--supporting: var(--tone-700);
  --text-color--unimportant: var(--tone-700);
  //placeholder, unimportant text
  --text-color--placeholder: var(--tone-600);

  //page headings, important card headings
  --heading--highlight-color: var(--tone-800 );

  --primary-interactive-color: var(--primary-700);
  --primary-interactive-color--hover: var(--primary-800);
  --primary-interactive-color--active: var(--primary-800);
  --primary-interactive-contrast-color: var(--tone-100);
  --primary-interactive-contrast-color--hover: var(--tone-100);
  --primary-interactive-contrast-color--active: var(--tone-100);

  // Use for:
  // tertiary & toggle buttons
  // card highlight (when triggered by secondary element)
  --secondary-interactive-color: var(--secondary-700);
  --secondary-interactive-color--hover: var(--secondary-800);
  --secondary-interactive-color--active: var(--secondary-800);
  --secondary-interactive-contrast-color: var(--tone-100);
  --secondary-interactive-contrast-color--hover: var(--tone-100);
  --secondary-interactive-contrast-color--active: var(--tone-100);

  //typeahead drop down selected item bg
  --typeahead-bg-color: var(--tone-400);

  // Colour mapping
  --footer: var(--tone-800);
  --footer--contrast: var(--tone-100);

  --separator: var(--tone-400);
  --panel-bg--highlight: var(--tone-300);

  --decoration-color-1: #f96155;
  --decoration-color-2: #f1a14e;
  --decoration-color-3: #3b8524;
  --decoration-color-4: #4a6dce;

  --attention: #AB251B;

  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;

  @include wlo.rule-overrides();
  @include wlo.set-vars();
  @include typography-preset.set-vars();

  //dark mode
  //--page-bg: var(--tone-900);
  //--text-color: var(--tone-100);
  //--text-color--supporting: var(--tone-200);
  //--text-color--placeholder: var(--tone-300);
  //
  //--input-background-color: var(--tone-900);
  //--input-trigger-color: var(--tone-100);
  //--input-border-color: var(--tone-200);
  ////--input-text-color: var(--tone-100);
  ////--input-placeholder-color: var(--tone-200);
}
