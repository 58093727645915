@mixin -primary-button-variant-vars() {
  $background-color: var(--primary-800);
  $text-color: var(--tone-100);
  $hover-color: var(--primary-600);
  $active-color: var(--primary-700);
  $focus-border-color: var(--secondary-700);

  --btn-primary-color: #{$text-color};
  --btn-primary-background-color: #{$background-color};
  --btn-primary-border-color: #{$background-color};

  --btn-primary-focus-border-color: #{$focus-border-color};

  --btn-primary-active-color: #{$text-color};
  --btn-primary-active-background-color: #{$active-color};
  --btn-primary-active-border-color: #{$active-color};

  --btn-primary-hover-color: #{$text-color};
  --btn-primary-hover-background-color: #{$hover-color};
  --btn-primary-hover-border-color: #{$hover-color};

  --btn-primary-disabled-color: var(--btn-primary-color);
  --btn-primary-disabled-background-color: var(--btn-primary-background-color);
  --btn-primary-disabled-border-color: var(--btn-primary-border-color);
  --btn-primary-disabled-hover-color: var(--btn-primary-hover-color);
  --btn-primary-disabled-hover-background-color: var(
      --btn-primary-hover-background-color
  );
  --btn-primary-disabled-hover-border-color: var(
      --btn-primary-hover-background-color
  );
}

@mixin -primary-inverted-button-variant-vars() {
  --btn-primary-inverted-color: var(--primary-800);
  --btn-primary-inverted-background-color: var(--primary-300);
  --btn-primary-inverted-border-color: var(--primary-300);

  --btn-primary-inverted-hover-color: var(--primary-800);
  --btn-primary-inverted-hover-background-color: var(--primary-400);
  --btn-primary-inverted-hover-border-color: var(--primary-400);

  --btn-primary-inverted-active-color: var(--primary-800);
  --btn-primary-inverted-active-background-color: var(--primary-400);
  --btn-primary-inverted-active-border-color: var(--primary-300);

  --btn-primary-inverted-focus-border-color: var(--primary-400);

  --btn-primary-inverted-disabled-color: var(--btn-primary-inverted-color);
  --btn-primary-inverted-disabled-background-color: var(--btn-primary-inverted-background-color);
  --btn-primary-inverted-disabled-border-color: var(--btn-primary-inverted-border-color);
  --btn-primary-inverted-disabled-hover-color: var(--btn-primary-inverted-hover-color);
  --btn-primary-inverted-disabled-hover-background-color: var(
      --btn-primary-inverted-hover-background-color
  );
  --btn-primary-inverted-disabled-hover-border-color: var(
      --btn-primary-inverted-hover-background-color
  );
}

@mixin -secondary-button-variant-vars() {
  $background-color: var(--tone-100);
  $color: var(--primary-800);
  $active-color: var(--primary-700);
  $focus-border-color: var(--secondary-700);
  $text-color: var(--primary-800);

  --btn-secondary-color: #{$text-color};
  --btn-secondary-background-color: #{$background-color};
  --btn-secondary-border-color: #{$color};

  --btn-secondary-focus-border-color: #{$focus-border-color};

  --btn-secondary-active-color: #{$active-color};
  --btn-secondary-active-background-color: #{$background-color};
  --btn-secondary-active-border-color: #{$active-color};

  --btn-secondary-hover-color: #{$active-color};
  --btn-secondary-hover-background-color: #{$background-color};
  --btn-secondary-hover-border-color: #{$active-color};

  --btn-secondary-disabled-color: var(--btn-secondary-color);
  --btn-secondary-disabled-background-color: var(
      --btn-secondary-background-color
  );
  --btn-secondary-disabled-border-color: var(--btn-secondary-border-color);
  --btn-secondary-disabled-hover-color: var(--btn-secondary-hover-color);
  --btn-secondary-disabled-hover-background-color: var(
      --btn-secondary-hover-background-color
  );
  --btn-secondary-disabled-hover-border-color: var(
      --btn-secondary-hover-background-color
  );
}

@mixin -toggle-button-variant-vars() {
  $main-color: var(--secondary-700);
  $contrast-color: var(--tone-100);
  --btn-toggle-color: var(--tone-700);
  --btn-primary-text-decoration: none;
  --btn-toggle-background-color: #{$contrast-color};
  --btn-toggle-border-color: #{$main-color};
  --btn-toggle-hover-color: #{$contrast-color};
  --btn-toggle-hover-text-decoration: none;
  --btn-toggle-hover-background-color: #{$main-color};
  --btn-toggle-hover-border-color: #{$main-color};
  --btn-toggle-active-color: #{$contrast-color};
  --btn-toggle-active-text-decoration: none;
  --btn-toggle-active-background-color: #{$main-color};
  --btn-toggle-active-border-color: #{$main-color};
  --btn-toggle-focus-border-color: var(--btn-toggle-hover-border-color);
  --btn-toggle-disabled-color: var(--btn-toggle-color);
  --btn-toggle-disabled-text-decoration: var(--btn-toggle-text-decoration);
  --btn-toggle-disabled-background-color: var(--btn-toggle-background-color);
  --btn-toggle-disabled-border-color: var(--btn-toggle-border-color);
  --btn-toggle-disabled-hover-color: var(--btn-toggle-hover-color);
  --btn-toggle-disabled-hover-text-decoration: var(
      --btn-toggle-hover-text-decoration
  );
  --btn-toggle-disabled-hover-background-color: var(
      --btn-toggle-hover-background-color
  );
  --btn-toggle-disabled-hover-border-color: var(
      --btn-toggle-hover-border-color
  );
}

@mixin set-vars() {
  --btn-height: auto;
  --btn-disabled-shadow: none;
  --btn-disabled-opacity: 0.5;
  --btn-transition: background-color var(--ease-transition),
  color var(--ease-transition), border-color var(--ease-transition),
  opacity var(--ease-transition);

  @include -primary-button-variant-vars();
  @include -primary-inverted-button-variant-vars();
  @include -secondary-button-variant-vars();
  //note that tertiary buttons are not used anywhere in the Whitelabel solution,
  @include -toggle-button-variant-vars();
}
