@use 'whitelabel/buttons';
@use "../abstracts/breakpoints" as bp;
@use "../abstracts/typography" as t;
@use "../abstracts/spacing" as s;

@mixin rule-overrides {
  .tooltip {
    &__content[class] {
      //as sales are keeping spacing-unit-min-lg this looks bad on sales - keeping override until this is resolved
      @include s.padding(2);
    }
  }
}

@mixin set-vars {
  @include buttons.set-vars();
  --form-card-bg: var(--tone-200);
  --input-padding: 16px;
  --input-error-icon-circle-size: 24px;
  --input-error-icon-size: 12px;
  --input-border-color--focus: var(--tone-600);
  --input-shadow: none;
  --card-highlight-width: 4px;
}
